import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `text-summer-red-600 text-category-sm md:text-category-lg`,
    description: `text-preamble-sm`,
  },
  variants: {
    variant: {
      horizontal: {
        description: `sm:container-col-sm-7:text-preamble-lg sm:container-col-lg-7:text-preamble-lg sm:container-col-sm-7:mt-3`,
        headline: `sm:container-col-sm-4:text-headline-md sm:container-col-lg-7:text-headline-xl`,
      },
      vertical: {
        base: `gap-3`,
        caption: `group-[.bg]/box:mx-4`,
        description: `container-col-lg-5:text-preamble-sm container-col-lg-7:text-preamble-sm sm:container-col-lg-5:text-preamble-lg sm:container-col-lg-7:text-preamble-lg container-col-sm-7:text-body-sm sm:text-body-sm`,
        headline: `group-[.bg]/box:mx-4 group-[.bg]/box:mb-4 container-col-sm-7:text-headline-lg sm:container-col-lg-4:text-headline-md sm:container-col-lg-5:text-headline-lg sm:container-col-lg-7:text-headline-lg sm:container-col-lg-8:text-headline-2xl`,
      },
      card: {
        base: `w-full auto-rows-min gap-0`,
        caption: `group-[.bg]/box:mx-4`,
        description: `container-col-lg-5:text-preamble-sm container-col-lg-7:text-preamble-sm sm:container-col-lg-5:text-preamble-lg sm:container-col-lg-7:text-preamble-lg`,
        group: `bg-linen-100 relative -top-6 ml-6 p-3 md:-top-9 md:left-9 md:ml-0 md:w-[80%] md:bg-white container-col-lg-7:md:p-9`,
        headline: `text-headline-md container-col-lg-4:md:before:hidden container-col-lg-7:md:text-headline-xl container-col-lg-7:md:before:block [&>span.underline]:relative [&>span.underline]:inline-block [&>span.underline]:no-underline [&>span.underline]:before:absolute [&>span.underline]:before:-bottom-1.5 [&>span.underline]:before:h-2.5 [&>span.underline]:before:w-[102%] [&>span.underline]:before:bg-[url('/image/article-card-blob.svg')] [&>span.underline]:before:bg-no-repeat`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
